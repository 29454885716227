import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { HashRouter, Route, Routes } from "react-router-dom";
//import { BrowserRouter, Route, Routes } from "react-router-dom";
import CocktailCardDetail from "./components/cocktailCardDetail";
import Home from "./components/home";
import About from "./components/about";
import NavigationBar from "./components/navigationBar";
import Category from "./components/category";
import { DataContextProvider } from "./provider/DataContext";
import Longdrinks from "./components/longdrinks";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <NavigationBar />
    <HashRouter>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/cocktails"
          element={
            <DataContextProvider page="cocktails">
              <App />
            </DataContextProvider>
          }
        />
        <Route
          path="/longdrinks"
          element={
            <DataContextProvider page="longdrinks">
              <Longdrinks />
            </DataContextProvider>
          }
        />
        <Route
          path="/:id"
          element={
            <DataContextProvider page="cocktails">
              <CocktailCardDetail />
            </DataContextProvider>
          }
        />
        <Route
          path="/categories/:category"
          element={
            <DataContextProvider page="cocktails">
              <Category />
            </DataContextProvider>
          }
        />
        <Route path="/" element={<Home />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);
reportWebVitals();
