import { ICocktail } from "../models/interfaces";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface DataContextType {
  data: any; // Define the data type accordingly
  fetchData: (page: string) => Promise<void>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const useData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error("useData must be used within a DataContextProvider");
  }
  return context;
};

interface DataContextProviderProps {
  children: React.ReactNode; // Define children prop
  page: string; // Define a prop to pass the page
}

export const DataContextProvider: React.FC<DataContextProviderProps> = ({
  children,
  page,
}) => {
  const [data, setData] = useState<any>(null);

  const fetchData = async (page: string) => {
    try {
      // Fetch data from your JSON file or API
      const response = await fetch(`${page}.json`);

      /*
      const response = await fetch(
        `http://localhost:3000/NobleTenders/${page}.json`
      );
      */

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const jsonData: ICocktail[] = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  return (
    <DataContext.Provider value={{ data, fetchData }}>
      {children}
    </DataContext.Provider>
  );
};
