import { ICocktail } from "../models/interfaces";
import { useData } from "../provider/DataContext";
import "../styles/style.css";
import CocktailCardList from "./cocktailCardList";

function Longdrinks() {
  const { data } = useData();

  if (!data) {
    return (
      <div id="loading">
        <button className="btn btn-dark" type="button" disabled>
          <span
            className="spinner-border spinner-border-sm"
            aria-hidden="true"
          ></span>
          <span role="status">Loading...</span>
        </button>
      </div>
    );
  }

  const cocktails: ICocktail[] = data;
  const sortedCocktails = [...cocktails].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  console.log("Longdrinks");
  return (
    <div>
      <h1 className="news-header">Longdrinks</h1>
      <CocktailCardList cocktails={sortedCocktails} />
    </div>
  );
}
export default Longdrinks;
